import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import illustration from '../../resources/lottie-animations/components.json';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnimatedIllustration = makeShortcode("AnimatedIllustration");
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimatedIllustration data={illustration} css={{
      marginBottom: 32
    }} mdxType="AnimatedIllustration" />
    <h1 {...{
      "id": "components-explained"
    }}>{`Components Explained`}</h1>
    <p>{`Components are interactive UI elements with a specific functional behaviour. These can vary in
complexity: from basic components like buttons or text inputs, to more complicated components like a
color picker. All of the components within Canvas have been created using `}<a parentName="p" {...{
        "href": "/styles/tokens/overview"
      }}>{`Canvas Tokens`}</a>{`
which are consistent preset stylings used in both design libraries and code libraries alike. Think
of these like the DNA that makes Workday products look and feel like they’re part of the same
family.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`The components in the official Canvas Figma libraries and
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit"
        }}>{`Canvas Kit`}</a>{` code repository have been created to be as
universal, flexible and scaleable as possible. This means they can be of most value to the most
amount of teams using them. Our mission is to provide you with high quality flexible building blocks
so you can efficiently build a diverse set of cohesive Workday experiences.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`The components in `}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit"
        }}>{`Canvas Kit`}</a>{` code repository have been
created to be as universal, flexible and scaleable as possible. This means they can be of most value
to the most amount of teams using them. Our mission is to provide you with high quality flexible
building blocks so you can efficiently build a diverse set of cohesive Workday experiences.`}</p>
    </ExternalContent>
    <h2 {...{
      "id": "components-vs-patterns"
    }}>{`Components vs Patterns`}</h2>
    <p>{`Components and `}<a parentName="p" {...{
        "href": "/patterns/overview"
      }}>{`Patterns`}</a>{` are intrinsically related, but they are not the same.
Components in and of themselves lack context. They are the essential building blocks by which UI
solutions are built. Components help development teams to quickly and efficiently build UI solutions
in a modular way.`}</p>
    <p>{`Core patterns classify and document reusable UI solutions, or 'ways of doing things' built to
respond to common user problems. An example of a patterns is the convention of how search bars and
results work on eCommerce websites. Or the pattern of error messages reacting to user interactions.
The patterns and components we use in Canvas are solutions built with similar visuals. These use a
combination of various Canvas components and tokens, which provide the visual UI layer which users
interact with.`}</p>
    <h2 {...{
      "id": "custom-components"
    }}>{`Custom Components`}</h2>
    <p>{`Looking to create custom components with Canvas? Check out our Getting Started guidance for
`}<a parentName="p" {...{
        "href": "/get-started/for-designers/customizing-canvas#tab=custom-components"
      }}>{`creating custom components`}</a>{`
with Canvas.`}</p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "contribute-a-component"
      }}>{`Contribute A Component`}</h2>
      <p>{`Looking to contribute a component to Canvas? Check out our Getting Started guidance for contribution
`}<a parentName="p" {...{
          "href": "/get-started/for-designers/contributing-to-canvas/"
        }}>{`for designers`}</a>{` or
`}<a parentName="p" {...{
          "href": "/get-started/for-developers/contributing-to-canvas"
        }}>{`for developers`}</a>{` to learn more.`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      